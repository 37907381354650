

import Image from "next/image";
import Link from "next/link";

const Logo = () => 
{
  // { bgWhite = true }

  return (
    <div>
                {/* <Link href="/">
                  <a className="shrink-0">
                    <img
                      src="/images/logo.png"
                      alt="Cakery blockchain equity slice"
                      width={209}
                      height={60}
                      className=" h-auto dark:hidden"
                    />

                    <img
                      src="/images/logo_white.png"
                      className=" h-auto hidden dark:block"
                      width={209}
                      height={60}
                      alt="Cakery blockchain equity slice"
                    />
                  </a>
                </Link> */}


      <Link href="#">
        <Image
          src={"/images/logo_white@2x.png"}
          alt="Cakery blockchain equity slice pie cakes"
          className="dark:hidden"
          width={230}
          height={59}
        />
              </Link>
      {/* <Image
          src="/images/logo.png"
          alt="Cakery blockchain equity slice dark"
          className="hidden dark:block"
          width={209}
          height={60}
        />  */}

    </div>
  )
};



export default Logo;