import React from "react";
import Link from "next/link";
import Logo from "./logo";
import HeadLine from './headLine';
import { footerMenuList, socialIcons } from "../data/footer_data";
// import BuiltWithNear from '/images/BuiltWithNear.svg'
// import {
// 	Subscribe,
// } from 'components/hero/subscribe';

import DiscordSubscribe from "./discord-subscribe";
import DiscordGated from "./discord-gated";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">

  				<HeadLine
  					text="The new way to startup"
  					classes="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white"
  				/>
          
          <div className="pt-12 pb-12 grid lg:grid-cols-3">
            <div className="">
              {/* <!-- Logo --> */}

              <Logo/>

              <p className="dark:text-jacarta-300">
                The dApp for contribution tracking &amp; equity slicing. <br/>
                Powered by blockchain 
              </p>
              <div className="w-full  mb-12">
                {/* <BuiltWithNear/> */}

                {/* <img
                      src="/images/near.svg"
                      className="hidden max-h-8 dark:block"
                      alt="Cakery blockchain equity slice"
                    /> */}

               </div>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            {/* {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link href={href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))} */}
{/* 
"col-span-9 md:col-span-8" */}

            <div className="">
                &nbsp;
            </div>
            <div className="">
             
{/*               <p className="text-jacarta-700 mx-auto mt-5 mb-5 max-w-2xl text-center text-lg dark:text-white">
                Get notified on launch 🚀 
              </p> */}
                {/* Get DAO benefits: */}
{/*               <DiscordSubscribe/> */}
              <DiscordGated/>
              {/* <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                <li>Save on legals</li>
                <li>Simple member voting</li>
                <li>Delay formalization</li>
                <li>Community rewards.</li>
              </ul> */}

            </div>

          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} <a href="https://envisageapps.com/">Envisage Apps</a></span>
            </span>

            {/* <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li> */}
              {/* <span className="dark:text-jacarta-400 text-sm">
                DAO = Decentralized Autonomous Organization.
              </span> */}
{/*               <span className="dark:text-jacarta-400 text-sm">
                <Link href="/privacy">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy policy
                  </a>
                </Link>
              </span> */}
            {/* </ul> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
