import Link from "next/link";


const DiscordGated = () => {
  return (
    <>
      <div className="flex row dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-lg border py-3 px-4 dark:text-white dark:placeholder-white">
        <div className="w-2/5 content-center"> 
          <div className="ml-5">
            <Link href="https://cal.com/nchallen/greet">
                
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="group cursor-pointer"
              >
                <svg className="icon group-hover:fill-accent fill-accent 0 h-20 w-20 dark:group-hover:fill-white">
                  <use xlinkHref={`/icons.svg#icon-peoples`}></use>
                </svg>
              </a>
            </Link>
          </div>
        </div>
        <div className="w-3/5"> 
          <div className="ml-5">
              <p>Book a discovery call</p> 
              <ul>
                <li>&nbsp;&nbsp;👀 Sneak peeks</li>
                <li>&nbsp;&nbsp;📲 First access</li>
                <li>&nbsp;&nbsp;🌍 Join the community</li>
              </ul>
          </div>
        </div> 

      </div>

    </>
  );


}

export default DiscordGated;
