import React from "react";
import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Accord || equity pie slicing dApp on the blockchain",
  keyword:
    "equity, cake, pie, split, contributions, member, goveranance, blockchain, crypto, bootrapping, DAO, web3, job marketplace, wallet, tokens, incentive, reward, pulley, cap table, stakeholder, alternative, Bootrapping founders, web3 communities, DAOs track equity slicing on-chain. Reward, incentivize, track contributions",
  desc: "Accord solves a problem every multi founder team experiences. The imperfect approach for startups splits equity equally. Instead reward verified contributions on-chain. Bake a Bigger Cake! Build a Longer Table.",
};

export default Meta;
